<template>
  <ul class="catalog__list list-unstyled flex-wrap row">
    <li
      v-for="(product, index) in products"
      :key="index"
      class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6"
    >
      <product-card :product="product" />
    </li>
  </ul>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";

export default {
  name: "ProductsSearch",
  props: ["products"],
  components: {
    ProductCard,
  },
  unmounted() {
    // console.log("unmonted productsearch");
    this.$store.dispatch("search/resetSearchProducts");
  },
};
</script>
