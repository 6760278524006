<template>
  <div
    class="VuePagination"
    :class="props.theme.wrapper"
    aria-label="catalog-product-list"
  >
    <nav :class="props.theme.nav" class="mb-4" aria-label="catalog-product-list">
      <ul
        v-show="props.showPagination"
        :class="props.theme.list"
        class="pagination pagination-circle container"
      >
        <li
          v-if="props.hasEdgeNav"
          :class="props.theme.firstPage"
          @click="props.setFirstPage"
          class="firstp"
        >
          <!-- <a type="button" v-bind="props.firstPageProps">{{props.texts.first}}</a> -->
          <a type="button" v-bind="props.firstPageProps"
            ><img src="@/assets/images/pagination/first_link.png"
          /></a>
        </li>

        <li
          v-if="props.hasChunksNav"
          :class="props.theme.prevChunk"
          @click="props.setPrevChunk"
        >
          <a type="button" v-bind="props.prevChunkProps">{{ props.texts.prevChunk }}</a>
        </li>

        <li :class="props.theme.prev" class="prev" @click="props.setPrevPage()">
          <!-- <a type="button" v-bind="props.prevProps">{{props.texts.prevPage}}</a> -->
          <a
            v-if="isMiddleDevice"
            type="button"
            v-bind="props.prevProps"
            style="color: #000"
            >Inapoi</a
          >
          <a v-else type="button" v-bind="props.prevProps"
            ><img src="@/assets/images/pagination/prev_link.png"
          /></a>
        </li>

        <li
          v-for="page in props.pages"
          :key="page"
          :class="props.pageClasses(page)"
          v-on="props.pageEvents(page)"
          class="num_link"
        >
          <a type="button" v-bind="props.aProps" :class="props.theme.link">{{ page }}</a>
        </li>

        <li :class="props.theme.next" class="next" @click="props.setNextPage">
          <!-- <a type="button" v-bind="props.nextProps">{{props.texts.nextPage}}</a> -->
          <a
            v-if="isMiddleDevice"
            type="button"
            v-bind="props.nextProps"
            style="color: #000"
            >Inainte</a
          >
          <a v-else type="button" v-bind="props.nextProps">
            <img src="@/assets/images/pagination/next_link.png" />
          </a>
        </li>

        <li
          v-if="props.hasChunksNav"
          :class="props.theme.nextChunk"
          @click="props.setNextChunk"
        >
          <a type="button" v-bind="props.nextChunkProps">{{ props.texts.nextChunk }}</a>
        </li>

        <li
          v-if="props.hasEdgeNav"
          class="last"
          :class="props.theme.lastPage"
          @click="props.setLastPage"
        >
          <!-- <a  type="button" v-bind="props.lastPageProps">{{props.texts.last}}</a> -->
          <a type="button" v-bind="props.lastPageProps"
            ><img src="@/assets/images/pagination/last_link.png"
          /></a>
        </li>

        <li class="all" @click="displayAllProducts">
          <a type="button">vezi toate produsele</a>
        </li>
      </ul>
      <p v-show="props.hasRecords" :class="props.theme.count">{{ props.count }}</p>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SearchPagination",
  props: ["props"],
  // created() {
  //     console.log(this.props.theme);
  // }
  mounted() {
    // console.log(this.props);
  },
  computed: {
    isMiddleDevice() {
      return this.$mq === "sm" ? true : false;
    },
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
  },
  methods: {
    displayAllProducts() {
      this.$store.dispatch("shop/changeSelFromShop", true);

      this.$router.push({
        name: "searchpage",
        params: { page: "all" },
        query: { name: this.$route.query.name },
      });

      this.$store.dispatch("shop/actionSpinner", true);

      var anchor = document.querySelector("#app");
      anchor.scrollIntoView({
        behavior: "smooth",
      });

      this.$store.dispatch("shop/actPageAll", true);
    },
  },
};
</script>

<style>
@import "../../assets/css/pagination.css";
</style>
