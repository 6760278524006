<template>
  <ul class="catalog__list list-unstyled flex-wrap row">
    <template v-if="isDataLoaded">
      <li
        v-for="(product, index) in products"
        :key="index"
        class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6"
      >
        <product-categ-card v-if="isFullWidthContent(product)" :product="product" />
        <product-card v-else :product="product" />
      </li>
      <!-- <div class="col-12 vld-parent" v-if="isLoading">
        <loading :active="isLoading" :is-full-page="false"></loading>
      </div> -->
    </template>

    <template v-else>
      <SkeletonProductCard v-for="n in 8" :key="n" />
    </template>
  </ul>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";
import SkeletonProductCard from "@/components/blocks/skeleton/shop/SkeletonProductCard.vue";
import { shopMixin } from "@/mixins/shopMixin";
// Import component
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  props: ["allProducts"],
  components: {
    ProductCard,
    SkeletonProductCard,
    // Loading,
  },
  data() {
    return {
      products: [],
      currentPage: 0,
      totalPages: "",
      showSpinner: true,
      isLoading: false,
    };
  },
  watch: {
    isDataLoaded: async function () {
      this.initProducts();
    },
  },
  computed: {
    hasFetchedAllData() {
      return this.currentPage > this.totalPages;
    },
    isDataLoaded() {
      return this.$store.getters["search/isDataLoaded"];
    },
    totalProducts() {
      return this.$store.getters["search/total"];
    },
  },
  mixins: [shopMixin],
  methods: {
    async handleScroll() {
      // if (
      // window.innerHeight + window.scrollY >=
      // document.body.offsetHeight - document.body.offsetHeight / 4
      // ) {
      console.log(this.hasFetchedAllData);
      if (this.hasFetchedAllData || this.isLoading) {
        // console.log("iese");
        return;
      }

      await this.fetchProducts();
      // setTimeout(() => (this.isLoading = false), 3000);
      // }
    },
    async fetchProducts() {
      try {
        this.isLoading = true;
        this.currentPage++;

        const payload = {
          search: this.$route.query.name,
          page: this.currentPage,
        };

        console.log("intra aici", this.$route.query.name);
        this.$store
          .dispatch("search/search", payload)
          .then(() => {
            Array.prototype.push.apply(this.products, this.allProducts);
          })
          .then(() => {
            this.isLoading = false;
            // console.log(this.hasFetchedAllData);
            // console.log(this.totalPages);
            // if (this.hasFetchedAllData) {
            // return;
            // }
            // this.fetchProducts();
          });
      } catch (err) {
        console.log(err); // state-of-the-art-error-handling
      }
    },
    async initProducts() {
      console.log("total products", this.totalProducts);
      this.totalPages = await Math.floor(this.totalProducts / 20);
      console.log("total pages", this.totalPages);
      await this.fetchProducts();
    },
  },
  created() {
    if (this.isDataLoaded) {
      this.initProducts();
    }
  },
  mounted() {
    // console.log(this.currentPage);
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$store.dispatch("search/resetSearchProducts");
  },
};
</script>
