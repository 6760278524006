<template>
  <div class="products-list-page" id="listproducts">
    <page-header />
    <slogan-livrare />
    <!-- <text-preview /> -->

    <section class="catalog container mt-4">
      <loading :active="spinner && $route.params.page !== 'all'">
        <spinner :spinner="spinner">
          Se cauta produse
          <template v-slot:noitems>Nu mai sunt produse de incarcat</template>
        </spinner>
      </loading>

      <products-search
        v-if="$route.params.page !== 'all' && products?.length"
        :products="products"
      ></products-search>

      <products-all-search
        v-if="$route.params.page === 'all' && isDataLoaded"
        :allProducts="products"
      ></products-all-search>

      <div
        v-if="isDataLoaded && products?.length === 0 && $route.params.page !== 'all'"
        class="pf-no-products"
      >
        <p>Nu exista nici un produs care sa contina criteriile selectate.</p>
        <p>Va rog schimbati criterile de filtrare!</p>
      </div>

      <!-- <pagination /> -->
      <div class="bottom-pagination">
        <pagination
          v-if="lastPage > 1 && isDataLoaded && $route.params.page !== 'all'"
          v-model="page"
          :records="total"
          :per-page="per_page"
          :options="paginationOptions"
          @paginate="myCallback"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { shallowRef } from "vue";

import PageHeader from "@/components/page_header/PageHeader.vue";
import SloganLivrare from "@/components/product_list/SloganLivrare.vue";
import ProductsSearch from "@/components/product_list/ProductsSearch.vue";
import SearchPagination from "@/components/pagination/SearchPagination.vue";
import Pagination from "v-pagination-3";
import ProductsAllSearch from "@/components/product_list/ProductsAllSearch.vue";
import Spinner from "@/components/blocks/Spinner.vue";
// Import component
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "SearchList",
  components: {
    "page-header": PageHeader,
    "slogan-livrare": SloganLivrare,
    ProductsSearch,
    Pagination,
    ProductsAllSearch,
    Spinner,
    Loading,
  },

  data() {
    return {
      page: 1,
      per_page: 28,
      // searchWord: "",
    };
  },

  watch: {
    currentPage() {
      // console.log("pagina curenta", this.currentPage);
      this.page = this.currentPage;
    },
    total() {
      // console.log("total produse watch", this.total);
    },
  },

  computed: {
    products() {
      return this.$store.getters["search/products"];
    },

    total() {
      // console.log("test");
      return this.$store.getters["search/total"];
    },

    lastPage() {
      // return +this.$store.getters["search/lastPage"];
      return 3;
    },

    currentPage() {
      return this.$store.getters["search/page"];
    },

    spinner() {
      return this.$store.getters["shop/spinner"];
    },

    paginationOptions() {
      return {
        chunk: 3,
        chunksNavigation: scroll,
        edgeNavigation: true,
        texts: {
          count: "{page} din {pages}",
        },
        theme: "bootstrap4",
        template: shallowRef(SearchPagination),
      };
    },
    isDataLoaded() {
      return this.$store.getters["search/isDataLoaded"];
    },
    selFromshop() {
      return this.$store.getters["shop/selFromShop"];
    },
    inputValue() {
      return this.$store.getters["search/inputValue"];
    },
  },
  methods: {
    myCallback(event) {
      const payload = {
        search: this.inputValue,
        page: event,
      };

      // event poate fi diferit de current page

      if (event !== this.currentPage) {
        this.$store.dispatch("shop/changeSelFromShop", true);
        if (event === 1) {
          this.$router.push({
            name: "searchpage",
            params: { search: this.$route.query.name },
            query: { name: this.$route.query.name },
          });
        } else {
          this.$router.push({
            name: "searchpage",
            params: { search: this.$route.query.name, page: event },
            query: { name: this.$route.query.name },
          });
        }
        this.$store.dispatch("search/actPage", event);
      }

      this.$store.dispatch("search/search", payload);
      // console.log("payload", payload);
      var anchor = document.querySelector("#app");
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  // updated() {
  //   console.log("total produse updated", this.total);
  //   console.log("Data Loaded from updated", this.isDataLoaded);
  // },
  mounted() {
    // console.log("mounted", this.page);
    const payload = {
      search: this.$route.query.name,
      page: this.page,
    };

    // console.log(this.page);
    // if (this.products.length === 0) {
    this.$store.dispatch("search/search", payload);
    // }
  },
  created() {
    let page =
      this.$route.params.page && this.$route.params.page !== "all"
        ? this.$route.params.page
        : 1;
    this.$store.dispatch("search/syncSearchInput", this.$route.query.name);
    this.$store.dispatch("search/actPage", +page);
  },
  unmounted() {
    this.$store.dispatch("search/stateNavbar", false);
    this.$store.dispatch("search/resetSearchProducts");
  },
  async beforeRouteUpdate(to, from, next) {
    await this.$store.dispatch("search/syncSearchInput", to.query.name);

    if (this.selFromshop) {
      this.$store.dispatch("shop/changeSelFromShop", false);
    } else {
      // console.log("back button");
      let page = to.params.page ? to.params.page : 1;
      // if (from.params.page === "all") {
      //   this.$store.dispatch("search/search", {
      //     search: this.$route.query.name,
      //     page: page,
      //   });
      // } else
      if (to.params.page === "all") {
        this.$store.dispatch("search/search", {
          search: to.query.name,
          page: 1,
        });
      } else {
        this.$store.dispatch("search/changeDataLoaded", false).then(() => {
          this.$store.dispatch("search/actPage", +page).then(() => {
            this.myCallback(+page);
          });
        });
      }
    }

    next();
  },
};
</script>

<style scoped>
@import "../../assets/css/list_products.css";

.catalog {
  min-height: 480px;
}
</style>
